import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";

const DiscoverSection = () => {

	const [data, setData] = useState({});
	const [discoverData, setDiscoverData] = useState([]);
	const [discoverIcon, setDiscoverIcon] = useState([]);

	useEffect(() => {
		axios.get(`${BASE_URL}`)
			.then(res => {
				setData(res.data);
				setDiscoverData(res.data.discoverData);
				setDiscoverIcon(res.data.discoverIcon);
			})
			.catch(err => console.log(err))
	}, []);

	return (
		<section className="section discover-area bg-gray overflow-hidden ptb_100">
			<div className="container">
				<div className="row justify-content-between">
					<div className="col-12 col-lg-6 order-2 order-lg-1">
						{/* Discover Thumb */}
						<div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
							<img src={data.thumbOne} alt="" />
						</div>
					</div>
					<div className="col-12 col-lg-6 order-1 order-lg-2">
						{/* Discover Text */}
						<div className="discover-text pt-4 pt-lg-0">
							<h2 className="pb-4 pb-sm-0">{data.heading}</h2>
							<p className="d-none d-sm-block pt-3 pb-4">{data.headingText}</p>
							{/* Check List */}
							<ul className="check-list">
								{discoverData.map((item, idx) => {
									return (
										<div key={`do_${idx}`}>
											<li className="py-2">
												{/* List Box */}
												<div className="list-box media">
													<span className="icon align-self-center"><i className={item.iconClass} /></span>
													<span className="media-body ps-2">{item.text}</span>
												</div>
											</li>
										</div>
									);
								})}
							</ul>
							<div className="icon-box d-flex mt-3">
								{discoverIcon.map((item, idx) => {
									return (
										<div key={`il_${idx}`} className="service-icon pe-3">
											<span><i className={item.iconClass} /></span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> 
	);
};

export default DiscoverSection;
