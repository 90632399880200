import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const myForm = React.createRef();

  const changeHandler = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const submitHandler = event => {
    event.preventDefault();
    console.log(formData);
    myForm.current.reset();
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: ""
    });
    console.log(formData);
  };

  return (
    <div className="contact-box text-center">
      <form
        ref={myForm}
        onSubmit={submitHandler}
        className="contact-form"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                required="required"
                onChange={changeHandler}
                value={formData.name}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                required="required"
                onChange={changeHandler}
                value={formData.email}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Subject"
                required="required"
                onChange={changeHandler}
                value={formData.subject}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder="Message"
                required="required"
                onChange={changeHandler}
                value={formData.message}
              />
            </div>
          </div>
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-lg btn-block mt-3"
            >
              <span className="text-white pe-3">
                <i className="fas fa-paper-plane" />
              </span>
              Send Message
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;