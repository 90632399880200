import React, { useState, useEffect } from 'react';

const initData = {
    heading: "404",
    text: "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
    btnText: "Go to Homepage"
}

const ErrorPage = () => {
	const [initDataState, setInitData] = useState({});

	useEffect(() => {
		setInitData(initData);
	}, []);

	return (
		<div className="inner inner-pages">
			<div className="main">
			<section id="home" className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
				<div className="container h-100">
					<div className="row align-items-center justify-content-center h-100">
						<div className="col-12 col-md-7">
							<div className="welcome-intro error-area text-center">
								<h1 className="text-white">{initDataState.heading}</h1>
								<p className="text-white my-4">{initDataState.text}</p>
								<a href="/" className="btn sApp-btn text-uppercase">{initDataState.btnText}</a>
							</div>
						</div>
					</div>
				</div>
				</section>
			</div>
		</div>
	);
};

export default ErrorPage;