import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import ThemeTwo from "../themes/theme-two";
import ThemeThree from '../themes/theme-three';
import ThemeFour from "../themes/theme-four";
import ThemeFive from "../themes/theme-five";
import ThemeSix from "../themes/theme-six";
import BlogTwoColumn from "../components/Blogs/BlogTwoColumn";
import BlogThreeColumn from "../components/Blogs/BlogThreeColumn";
import BlogLeftSidebar from "../components/Blogs/BlogLeftSidebar";
import BlogRightSidebar from "../components/Blogs/BlogRightSidebar";
import BlogDetailsLeftSidebar from "../components/Blogs/BlogDetailsLeftSidebar";
import BlogDetailsRightSidebar from "../components/Blogs/BlogDetailsRightSidebar";
import Reviews from "../components/ReviewSection/Reviews";
import Pricing from "../components/PricingSection/Pricing";
import DownloadPage from "../components/DownloadSection/DownloadPage";
import SubscribePage from "../components/SubscribeSection/SubscribePage";
import ThankYou from "../components/InnerSection/ThankYou";
import ComingSoon from "../components/InnerSection/ComingSoon";
import Login from "../components/Accounts/Login";
import Signup from "../components/Accounts/Signup";
import Forgot from "../components/Accounts/Forgot";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
import ContactPage from "../components/ContactSection/ContactPage";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={ <ThemeOne /> } />
            <Route exact path="/theme-two" element={ <ThemeTwo /> } />
            <Route exact path="/theme-three" element={ <ThemeThree /> } />
            <Route exact path="/theme-four" element={ <ThemeFour /> } />
            <Route exact path="/theme-five" element={ <ThemeFive /> } />
            <Route exact path="/theme-six" element={ <ThemeSix /> } />
            <Route exact path="/blog-two-column" element={ <BlogTwoColumn /> } />
            <Route exact path="/blog-three-column" element={ <BlogThreeColumn /> } />
            <Route exact path="/blog-left-sidebar" element={ <BlogLeftSidebar /> } />
            <Route exact path="/blog-right-sidebar" element={ <BlogRightSidebar /> } />
            <Route exact path="/blog-details-left-sidebar" element={ <BlogDetailsLeftSidebar /> } />
            <Route exact path="/blog-details-right-sidebar" element={ <BlogDetailsRightSidebar /> } />
            <Route exact path="/reviews" element={ <Reviews /> } />
            <Route exact path="/pricing" element={ <Pricing /> } />
            <Route exact path="/download-page" element={ <DownloadPage /> } />
            <Route exact path="/subscribe-page" element={ <SubscribePage /> } />
            <Route exact path="/thank-you" element={ <ThankYou /> } />
            <Route exact path="/coming-soon" element={ <ComingSoon /> } />
            <Route exact path="/login" element={ <Login /> } />
            <Route exact path="/signup" element={ <Signup /> } />
            <Route exact path="/forgot" element={ <Forgot /> } />
            <Route exact path="/faq" element={ <Faq /> } />
            <Route exact path="/error-page" element={ <ErrorPage /> } />
            <Route exact path="/contact-page" element={ <ContactPage /> } />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default MyRouts;