import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";

const DiscoverTwo = () => {
	const [data, setData] = useState({});
	const [discoverData, setdiscoverData] = useState([]);
	const [discoverIcon, setdiscoverIcon] = useState([]);

	useEffect(() => {
		axios.get(`${BASE_URL}`)
			.then(res => {
				setData(res.data);
				setdiscoverData(res.data.discoverData);
				setdiscoverIcon(res.data.discoverIcon);
			})
			.catch(err => console.log(err))
	}, []);

	return (
		<section className="section discover-area bg-gray overflow-hidden ptb_100">
			<div className="container">
				<div className="row justify-content-between align-items-center">
				<div className="col-12 col-lg-6 order-2 order-lg-1">
					{/* Discover Thumb */}
					<div className="service-thumb discover-thumb mx-auto text-center mt-5 mt-lg-0">
						<img src={data.thumbTwo} alt="" />
					</div>
				</div>
				<div className="col-12 col-lg-6 order-1 order-lg-2">
					{/* Discover Text */}
					<div className="discover-text px-0 px-lg-4 pt-4 pt-lg-0">
					<h2 className="pb-4">{data.headingTwo}</h2>
					{/* Check List */}
					<ul className="check-list">
						{discoverData.map((item, idx) => {
							return(
								<div key={`dt_${idx}`}>
									<li className="py-2">
										{/* List Box */}
										<div className="list-box media">
											<span className="icon align-self-center"><i className={item.iconClass} /></span>
											<span className="media-body ps-2">{item.text}</span>
										</div>
									</li>
								</div>
							);
						})}
					</ul>
					<div className="icon-box d-flex mt-3">
					{discoverIcon.map((item, idx) => {
							return(
								<div key={`il_${idx}`} className="service-icon pe-3">
									<span><i className={item.iconClass} /></span>
								</div>
							);
						})}
					</div>
					</div>
				</div>
				</div>
			</div>
		</section>
	);
};

export default DiscoverTwo;