import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-2/themeOneSidebarData";

const Sidebar = () => {
	const [data, setData] = useState({});
	const [widgetData_1, setwidgetData_1] = useState([]);
	const [widgetData_2, setwidgetData_2] = useState([]);
	const [widgetData_3, setwidgetData_3] = useState([]);

	useEffect(() => {
		axios.get(`${BASE_URL}`)
			.then(res => {
				setData(res.data);
				setwidgetData_1(res.data.widgetData_1);
				setwidgetData_2(res.data.widgetData_2);
				setwidgetData_3(res.data.widgetData_3);
			})
			.catch(err => console.log(err))
	}, []);

	return (
		<div>
			<aside className="sidebar">
				{/* Single Widget */}
				<div className="single-widget">
					{/* Search Widget */}
					<div className="widget-content search-widget">
					<form action="#">
						<input type="text" placeholder="Enter your keywords" />
					</form>
					</div>
				</div>
				{/* Single Widget */}
				<div className="single-widget">
					{/* Category Widget */}
					<div className="accordions widget catagory-widget" id="cat-accordion">
					<div className="single-accordion blog-accordion">
						<h5>
						<a role="button" className="collapse show text-uppercase d-block p-3" data-bs-toggle="collapse" href="#accordion1">{data.widgetTitle_1}
						</a>
						</h5>
						{/* Category Widget Content */}
						<div id="accordion1" className="accordion-content widget-content collapse show" data-bs-parent="#cat-accordion">
							{/* Category Widget Items */}
							<ul className="widget-items">
								{widgetData_1.map((item, idx) => {
									return(
										<li key={`wdo_${idx}`}><a href="/#" className="d-flex p-3"><span>{item.text}</span><span className="ms-auto">{item.content}</span></a></li>
									);
								})}
							</ul>
						</div>
					</div>
					</div>
				</div>
				{/* Single Widget */}
				<div className="single-widget">
					{/* Post Widget */}
					<div className="accordions widget post-widget" id="post-accordion">
					<div className="single-accordion">
						<h5>
						<a role="button" className="collapse show text-uppercase d-block p-3" data-bs-toggle="collapse" href="#accordion2">{data.widgetTitle_2}
						</a>
						</h5>
						{/* Post Widget Content */}
						<div id="accordion2" className="accordion-content widget-content collapse show" data-bs-parent="#post-accordion">
							{/* Post Widget Items */}
							<ul className="widget-items">
								{widgetData_2.map((item, idx) => {
									return(
										<li key={`wdt_${idx}`}>
											<a href="/#" className="single-post media p-3">
												{/* Post Thumb */}
												<div className="post-thumb avatar-md">
													<img className="align-self-center" src={item.image} alt="" />
												</div>
												<div className="post-content media-body ps-3">
													<p className="post-date mb-3 mb-lg-2">{item.date}</p>
													<h6>{item.title}</h6>
												</div>
											</a>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
					</div>
				</div>
				{/* Single Widget */}
				<div className="single-widget">
					{/* Tags Widget */}
					<div className="accordions widget tags-widget" id="tags-accordion">
					<div className="single-accordion blog-accordion">
						<h5>
						<a role="button" className="collapse show text-uppercase d-block p-3" data-bs-toggle="collapse" href="#accordion3">{data.widgetTitle_3}
						</a>
						</h5>
						{/* Tags Widget Content */}
						<div id="accordion3" className="accordion-content widget-content collapse show" data-bs-parent="#tags-accordion">
							{/* Tags Widget Items */}
							<div className="widget-content tags-widget-items pt-2">
								{widgetData_3.map((item, idx) => {
									return(
										<a key={`wdth_${idx}`} href="/#" className="d-inline-block mt-2 me-1 px-2 py-1">{item.text}</a>
									);
								})}
							</div>
						</div>
					</div>
					</div>
				</div>
			</aside>
		</div>
	);
};

export default Sidebar;